<template>
  <v-main id="home">
    <router-view :key="$route.path" />
  </v-main>
</template>

<script>
export default {
  name: 'CoreView',
};
</script>
